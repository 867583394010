<template>
  <div>
    <v-data-table :loading="loading" disable-pagination disable-sort hide-default-footer :headers="headers"
      :items="rooms" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Contas Abertas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-menu :offset-y="true">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn color="primary" outlined icon large v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                    <v-icon>
                      {{ icons.mdiTools }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Ferramentas</span>
              </v-tooltip>
            </template>
            <v-list>
              <v-list-item @click="sendExportExcel()">
                <v-list-item-title>
                  <v-icon>{{ icons.mdiFileExcelOutline }}</v-icon> Exportar Excel
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small outlined large class="ml-2" color="primary"
                @click="sendExportPdf()">
                <v-icon>{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>Imprimir</span>
          </v-tooltip>
          <!-- <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <a :href="newTab" target="_blank" v-bind="attrs" v-on="on">
                            <v-btn class="ml-2" icon large outlined color="primary">
                                <v-icon>{{ icons.mdiOpenInNew  }}</v-icon>
                            </v-btn>
                        </a>
                    </template>
                    <span>Nova Aba</span>
                </v-tooltip> -->
          <!-- <v-badge
                    :content="bagFilter"
                    :value="bagFilter"
                    bordered
                    color="error"
                    overlap

                >
                <v-btn @click="dialogFilter=true" class="ml-2" rounded outlined large color="primary"><v-icon>{{icons.mdiFilter }}</v-icon>Filtros</v-btn>
                </v-badge> -->
          <v-spacer></v-spacer>

        </v-toolbar>
      </template>

      <template v-slot:item.checkin="{ item }">
        {{ item.current_accommodation ? formatDate(item.current_accommodation.date_checkin) : '' }}
      </template>

      <template v-slot:item.prev_checkout="{ item }">
        {{ item.prev_checkout ? formatDate(item.prev_checkout) : '' }}
      </template>

      <template v-slot:item.status="{ item }">
        {{ status(item.status) }}
      </template>
      <template v-slot:item.diary="{ item }">
        {{ formatMoney(item.diary) }}
      </template>
      <template v-slot:item.total_consumption="{ item }">
        {{ formatMoney(item.totalOpened) }}
     
      </template><template v-slot:item.total_payment="{ item }">
        {{ formatMoney(item.total_payment) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary" icon @click="showDetails(item)">
              <v-icon>{{ icons.mdiMagnify }}</v-icon>
            </v-btn>
          </template>
          <span>Detalhes</span>
        </v-tooltip>
      </template>
      <template v-slot:foot="{ item }">
        <tr style="height:44px; background:#fafafa">
          <td colspan="3" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">TOTAL DE QUARTOS
            :<b> {{ contRooms() }} </b> </td>
          <td colspan="3" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">TOTAL HOSPEDES: <b>
              {{ contPax() }} </b></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
        </tr>
        <tr style="height:44px; background:#fafafa">
          <td colspan="3" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">TOTAL DIÁRIA : <b>
              {{ formatMoney(totalDiary()) }} </b> </td>
          <td colspan="3" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">DIÁRIA MÉDIA (R$)
            :<b> {{ formatMoney(mediaDiary()) }} </b> </td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
          <td style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px"></td>
        </tr>
      </template>

      <template v-slot:no-data>
        <NoDataTable></NoDataTable>
      </template>

      <template v-slot:loading>
        Carregando...
      </template>
    </v-data-table>

    <!--DIALOG DETALHES DO QUARTO  -->
    <v-dialog v-model="dialogDetails" width="700">
      <v-card>
        <BarTitleDialog :title="'Detalhes do quarto - ' + room.description" @close="dialogDetails = false">
        </BarTitleDialog>
        <v-card-text>
          <v-row no-gutters>
            <v-col>
              <h3>Hospedagem:</h3>
              <v-row no-gutters>
                <v-col cols="7"><b>DESCRIÇÃO</b></v-col>
                <v-col class="text-right"><b>QTD.</b></v-col>
                <v-col class="text-right"><b>P.UNIT</b></v-col>
                <v-col class="text-right"><b>SUB. TOTAL</b></v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters v-for="(accommodation, index) in dialogDetailsAccommodation" key="index">
                <v-col cols="7">HOSPEDAGEM {{ formatDate(accommodation.date_start) + ' -
                  '+formatDate(accommodation.date_end)}}</v-col>
                <v-col class="text-right">1,00</v-col>
                <v-col class="text-right">{{ formatMoney(accommodation.value) }}</v-col>
                <v-col class="text-right">{{ formatMoney(accommodation.value) }}</v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="9"><b>Total (R$):</b></v-col>
                <v-col><b></b></v-col>
                <v-col><b></b></v-col>
                <v-col class="text-right"><b>{{ formatMoney(totalAccommodation) }}</b></v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>

              <h3>Produtos:</h3>
              <v-row no-gutters>
                <v-col cols="7"><b>DESCRIÇÃO</b></v-col>
                <v-col class="text-right"><b>QTD.</b></v-col>
                <v-col class="text-right"><b>P.UNIT</b></v-col>
                <v-col class="text-right"><b>SUB. TOTAL</b></v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters v-for="(product, index) in dialogDetailsProducts" key="index">
                <v-col cols="7">{{ product.description }}</v-col>
                <v-col class="text-right">{{ formatMoney(product.pivot.quantity) }}</v-col>
                <v-col class="text-right">{{ formatMoney(product.sale_price) }}</v-col>
                <v-col class="text-right">{{ formatMoney(product.sale_price * product.pivot.quantity) }}</v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="9"><b>Total (R$):</b></v-col>
                <v-col><b></b></v-col>
                <v-col><b></b></v-col>
                <v-col class="text-right"><b>{{ formatMoney(totalProduct) }}</b></v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <h3>Serviços:</h3>
              <v-row no-gutters>
                <v-col cols="7"><b>DESCRIÇÃO</b></v-col>
                <v-col class="text-right"><b>QTD.</b></v-col>
                <v-col class="text-right"><b>P.UNIT</b></v-col>
                <v-col class="text-right"><b>SUB. TOTAL</b></v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters v-for="(service, index) in dialogDetailsServices" key="index">
                <v-col cols="7">{{ service.description }}</v-col>
                <v-col class="text-right">{{ formatMoney(service.pivot.quantity) }}</v-col>
                <v-col class="text-right">{{ formatMoney(service.sale_price) }}</v-col>
                <v-col class="text-right">{{ formatMoney(service.sale_price * service.pivot.quantity) }}</v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="9"><b>Total (R$):</b></v-col>
                <v-col><b></b></v-col>
                <v-col><b></b></v-col>
                <v-col class="text-right"><b>{{ formatMoney(totalService) }}</b></v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-if="dialogDetailsPayments">
            <v-col>
              <h3>Adiantamentos:</h3>
              <v-row no-gutters>
                <v-col cols="7"><b>DESCRIÇÃO</b></v-col>
                <v-col class="text-right"><b>QTD.</b></v-col>
                <v-col class="text-right"><b>P.UNIT</b></v-col>
                <v-col class="text-right"><b>SUB. TOTAL</b></v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters v-for="(payment, index) in dialogDetailsPayments" key="index">
                <v-col cols="7">{{ paymentDescription(payment.method) }}</v-col>
                <v-col class="text-right">{{ formatMoney(1) }}</v-col>
                <v-col class="text-right"></v-col>
                <v-col class="text-right">{{ formatMoney(payment.value) }}</v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row no-gutters>
                <v-col cols="9"><b>Total (R$):</b></v-col>
                <v-col><b></b></v-col>
                <v-col><b></b></v-col>
                <v-col class="text-right"><b>{{ formatMoney(totalPayment) }}</b></v-col>
              </v-row>
            </v-col>
          </v-row>



          <v-row>
            <v-col cols="12" class="text-right" style=";font-size:16px"> Impostos (R$): <div
                style="float:right;width:70px">
                {{ formatMoney(room.iss) }}</div></v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="text-right" style="color:black;font-size:16px"><b> Total (R$): <div
                  style="float:right;width:70px">{{ formatMoney(totalAmount) }}</div></b></v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="text-right">
              <v-btn color="primary" x-large @click="printDetails()"><v-icon>{{ icons.mdiPrinter }}</v-icon>&nbsp
                Imprimir</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import NoDataTable from '@/components/NoDataTable.vue'
import { mdiTools, mdiFileExcelOutline, mdiFilter, mdiMagnify, mdiPrinter } from '@mdi/js'
import { mapActions } from 'vuex'
import BarTitleDialog from '@/components/BarTitleDialog.vue'
export default {
  components: {
    NoDataTable,
    BarTitleDialog,
  },
  data: () => ({
    dialogDetails: false,
    room: {},
    dialogDetailsProducts: [],
    dialogDetailsServices: [],
    dialogDetailsAccommodation: [],
    dialogDetailsPayments: [],
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    loading: false,
    headers: [
      {
        text: 'QUARTO',
        value: 'description',
      },
      {
        text: 'CATEGORIA',
        value: 'category.description',
      },
      {
        text: 'STATUS',
        value: 'status',
      },
      {
        text: 'CHECKIN',
        value: 'checkin',
      },
      {
        text: 'CHECKOUT',
        value: 'prev_checkout',
      },
      {
        text: 'PAX',
        value: 'pax',
      },
      {
        text: 'DIÁRIA',
        value: 'diary',
      },
      {
        text: 'TOTAL EM ABERTO',
        value: 'total_consumption',
        align: 'right',
      },
      {
        text: 'TOTAL ADIANTAMENTOS',
        value: 'total_payment',
        align: 'right',
      },
      {
        text: '',
        value: 'actions',
        align: 'end',
      },
    ],
    rooms: [],

    options: {},

    icons: {
      mdiTools,
      mdiFileExcelOutline,
      mdiFilter,
      mdiMagnify,
      mdiPrinter,
    },
  }),

  computed: {
    totalProduct() {
      return this.dialogDetailsProducts.reduce((acc, product) => {
        return acc + parseFloat(product.pivot.quantity) * parseFloat(product.sale_price)
      }, 0)
    },
    totalService() {
      return this.dialogDetailsServices.reduce((acc, service) => {
        return acc + parseFloat(service.pivot.quantity) * parseFloat(service.sale_price)
      }, 0)
    },
    totalPayment() {
      return this.dialogDetailsPayments.reduce((acc, payment) => {
        return acc + parseFloat(payment.value)
      }, 0)
    },
    totalAccommodation() {
      return this.dialogDetailsAccommodation.reduce((acc, accomodation) => {
        return acc + parseFloat(accomodation.value)
      }, 0)
    },
    totalAmount() {
      return (
        parseFloat(this.totalProduct) +
        parseFloat(this.totalService) +
        parseFloat(this.totalAccommodation) +
        parseFloat(this.room.iss)-
        parseFloat(this.totalPayment)

      )
    },
  },
  watch: {},

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('open_account', ['getAll', 'exportExcel', 'exportDetailsPdf', 'exportPdf']),
    initialize() {
      this.loading = true

      this.getAll()
        .then(response => {
          this.rooms = response.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    sendExportExcel() {
      this.exportExcel()
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.xlsx')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => { })
    },
    sendExportPdf() {
      this.exportPdf()
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => { })
    },

    showDetails(item) {
      this.room = item
      this.dialogDetailsProducts = item.current_accommodation.product
      this.dialogDetailsServices = item.current_accommodation.service
      this.dialogDetailsAccommodation = item.accommodationItemsCurrent
      this.dialogDetailsPayments = item.payments
      this.dialogDetails = true
    },
    printDetails() {
      this.exportDetailsPdf(this.room)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => { })
    },
    status(status) {
      switch (status) {
        case 'sujo':
          return 'Sujo'
        case 'bloqueado':
          return 'Bloqueado'
        case 'diaria':
          return 'Diária'
        case 'disponivel':
          return 'Disponível'
        case 'conferencia':
          return 'Conferência'
        case 'encerramento':
          return 'Encerramento'
        case 'limpeza':
          return 'Limpeza'
        case 'manutencao':
          return 'Manutenção'
        case 'ocupado':
          return 'Ocupado'
        case 'periodo':
          return 'Período'
        case 'pernoite':
          return 'Pernoite'
        case 'reservado':
          return 'Reservado'
        case 'stand-by':
          return 'Stand-by'
      }
    },
    contRooms() {
      return this.rooms.length
    },
    contPax() {
      return this.rooms.reduce((acc, room) => {
        return acc + parseFloat(room.pax)
      }, 0)
    },
    totalDiary() {
      return this.rooms.reduce((acc, room) => {
        //se for null não soma nada
        if (room.diary == null) {
          return acc
        }
        return acc + parseFloat(room.diary)
      }, 0)
    },
    mediaDiary() {
      const totalDiary = this.totalDiary()
      const countRooms = this.contRooms()
      if (totalDiary > 0) {
        return totalDiary / countRooms
      }
      return 0
    },

    formatDate(date) {
      if (date) {
        const result = new Date(date)
        return result.toLocaleDateString('pt-BR', {
          timeZone: 'America/Sao_Paulo',
        })
      }
    },
    formatMoney(value) {
      if (value == null) value = 0

      value = parseFloat(value)
      return value.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    },
    paymentDescription(method) {
      switch (method) {
        case 'money':
          return 'DINHEIRO';

        case 'credit_card':
          return 'CARTÃO DE CRÉDITO';

        case 'debit_card':
          return 'CARTÃO DE DÉBITO';

        case 'invoice':
          return 'FATURA';

        case 'b2b':
          return 'B2B';

        case 'transfer':
          return 'TRANSFERÊNCIA';

        case 'pix':
          return 'PIX';

        case 'other':
          return 'OUTROS';


      }
    },
  },
}
</script>
